import React, { Fragment } from 'react';

import Layout from '../App/components/layout';
import SignInForm from '../components/SignIn';
import { SignUpLink } from '../components/SignUp';
import { PasswordForgetLink } from '../components/PasswordForget';
import SEO from "../App/components/seo"

const SignInPage = () => (
  <Fragment>
    <SignInForm />
    <div className="wrapper maxWidth"><PasswordForgetLink /></div>
    <SignUpLink />
  </Fragment>
);

export default () => (
  <Layout>
    <SEO title="" />
    <SignInPage />
  </Layout>
);
